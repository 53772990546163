import { PrivateRoutes } from '../config';
import { rootStore } from '../store';
import {
  getPrevQuarterMonths,
  getYearsWithLimit,
  getYearsWithQuarters,
} from './helper';
import IrpServicesTodoFormConfig from './irpServicesForm.config';

const dirverLicenseTypes = ['CDL A', 'CDL B', 'CDL C', 'Non-CDL', 'Mexican'];
export const licenseTypesWithNoEndorsements = ['Non-CDL', 'Mexican'];

type Field = {
  data_type: string;
  label: string;
  name: string;
  config?: any;
  showLabel?: boolean;
};

const mutual_fields: Field[] = [
  {
    data_type: 'string',
    label: 'VIN#',
    name: 'vinNumber',
  },
  {
    data_type: 'string',
    label: 'Make',
    name: 'make',
  },
  {
    data_type: 'string',
    label: 'Unit#',
    name: 'unitNumber',
  },
  {
    data_type: 'string',
    label: 'Plate#',
    name: 'licensePlateNo',
  },
  {
    data_type: 'list->string',
    label: 'State',
    name: 'plateState',
  },
  {
    config: {
      minDate: new Date(),
    },
    data_type: 'date',
    label: 'Expiration Date',
    name: 'expirationDate',
  },
];

const getMaxDateForDOB = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

const getPastYears = (no_of_years = 1) => {
  let i = 1;
  let j = 0;
  const years = [];
  while (i <= no_of_years) {
    const date = new Date();
    const year = date.getFullYear() - j;
    years.push({ label: year, value: year });
    j++;
    i++;
  }
  return years;
};

const getFutureYears = (no_of_years = 1) => {
  let i = 1;
  let j = 0;
  const years = [];
  while (i <= no_of_years) {
    const date = new Date();
    const year = date.getFullYear() + j;
    years.push({ label: year, value: year });
    j++;
    i++;
  }
  return years;
};

const getHazmatPermitTypes = (no_of_years = 1) => {
  const permitTypes = [];
  let i = 1;
  while (i <= no_of_years) {
    permitTypes.push({ label: `${i} year permit`, value: `${i} Year` });
    i++;
  }
  return permitTypes;
};

const permitTypesForHazmatPermit = {
  config: {
    options: getHazmatPermitTypes(3),
  },
  data_type: 'list->string',
  label: 'Permit Type',
  name: 'permitType',
};

type Config = TodoFormConfig;

const tptsFormConfig: Config = {
  ...IrpServicesTodoFormConfig,
  'ar-request': {
    fields: [
      {
        config: {
          options: getPastYears(2),
        },
        data_type: 'list->string',
        label: 'Report Year',
        name: 'reportYear',
      },
      {
        config: {
          min: 0,
        },
        data_type: 'number',
        label: 'Miles Ran',
        name: 'milesRan',
      },
    ],
    title: 'AR Report Request',
  },
  'ar-update': {
    fields: [
      {
        config: {
          options: getPastYears(2),
        },
        data_type: 'list->string',
        label: 'Report Year',
        name: 'reportYear',
      },
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Date the Report Filed',
        name: 'reportFiledDate',
      },
    ],
    title: 'AR Report Update',
  },
  'dot-biennial': {
    fields: [
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Last Filed Date',
        name: 'reportFiledDate',
      },
    ],
    title: 'DOT Biennial Update',
  },
  'dot-vmt': {
    fields: [
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Last Filed Date',
        name: 'reportFiledDate',
      },
      {
        config: {
          min: 0,
        },
        data_type: 'number',
        label: 'Previous Years Mileage',
        name: 'milesRan',
      },
    ],
    title: 'DOT VMT Update',
  },
  fuel: {
    fields: [
      {
        config: {
          options: getYearsWithQuarters(1, 1).map((el) => ({
            label: el,
            value: el,
          })),
        },
        data_type: 'list->string',
        label: 'Filing Quarter',
        name: 'yearQuarter',
      },
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Report Filed on',
        name: 'reportFiledDate',
      },
    ],
    hasFileUpload: true,
    isFileOptional: true,
    title: 'Fuel Tax Filing',
  },
  'hazmat-request': {
    fields: [permitTypesForHazmatPermit],
    title: 'Hazmat Permit Request',
  },
  'hazmat-update': {
    fields: [
      permitTypesForHazmatPermit,
      {
        config: {
          minDate: new Date(),
        },
        data_type: 'date',
        label: 'Permit Expiration Date',
        name: 'expirationDate',
      },
    ],
    hasFileUpload: true,
    title: 'Hazmat Permit Update',
  },
  ifta: {
    fields: [
      {
        config: {
          msgForRegex: 'Value should be alphanumeric',
          regex: /^[a-z0-9]+$/i,
        },
        data_type: 'string',
        label: 'License number',
        name: 'licenseNumber',
      },
      {
        config: {
          options: getFutureYears(2),
        },
        data_type: 'list->string',
        label: 'License Year',
        name: 'licenseYear',
      },
      {
        data_type: 'list->string',
        label: 'State',
        name: 'licenseStateId',
      },
    ],
    hasFileUpload: true,
    title: 'IFTA Permit',
  },
  irp: {
    fields: [
      {
        data_type: 'string',
        label: 'VIN#',
        name: 'vinNumber',
      },
      {
        data_type: 'string',
        label: 'Make',
        name: 'make',
      },
      {
        config: {
          options: (() => {
            const date = new Date();
            const year = date.getFullYear() + 1;
            return [{ label: year, value: year }, ...getPastYears(30)];
          })(),
        },
        data_type: 'list->string',
        label: 'Year',
        name: 'year',
      },
      {
        data_type: 'string',
        label: 'Unit#',
        name: 'unitNumber',
      },
      {
        data_type: 'string',
        label: 'Plate#',
        name: 'licensePlateNo',
      },
      {
        data_type: 'list->string',
        label: 'State',
        name: 'plateState',
      },
      {
        config: {
          minDate: new Date(),
        },
        data_type: 'date',
        label: 'Expiration Date',
        name: 'expirationDate',
      },
      {
        data_type: 'primary_driver',
        label: 'Primary Driver',
        name: 'primaryDriverId',
      },
    ],
    hasFileUpload: true,
    title: 'Cab Card / IRP Registration',
  },
  'irp-permit': {
    fields: [
      {
        data_type: 'list->string',
        label: 'State',
        name: 'plateState',
      },
      {
        config: {
          minDate: new Date(),
        },
        data_type: 'date',
        label: 'Expiration Date',
        name: 'expirationDate',
      },
    ],
    hasFileUpload: true,
    title: 'IRP Permit',
  },
  local: {
    fields: [
      ...mutual_fields,
      {
        data_type: 'primary_driver',
        label: 'Primary Driver',
        name: 'primaryDriverId',
      },
    ],
    hasFileUpload: true,
    title: 'Local Vehicle Registration',
  },
  'or-monthly-request': {
    fields: [
      {
        config: {
          options: getPrevQuarterMonths().map((el) => ({
            label: el,
            value: el,
          })),
        },
        data_type: 'list->string',
        label: 'Previous Quarter months',
        name: 'yearQuarter',
      },
      {
        config: {
          min: 0,
        },
        data_type: 'number',
        label: 'Mileage Ran',
        name: 'milesRan',
      },
    ],
    title: 'OR Monthly Tax Filing Request',
  },
  'or-monthly-update': {
    fields: [
      {
        config: {
          options: getPrevQuarterMonths().map((el) => ({
            label: el,
            value: el,
          })),
        },
        data_type: 'list->string',
        label: 'Previous Quarter months',
        name: 'yearQuarter',
      },
      {
        config: {
          minDate: [
            (date_: string) => {
              const date = new Date(date_);
              date.setMonth(date.getMonth() + 1);
              return date;
            },
            'yearQuarter',
          ],
        },
        data_type: 'date',
        label: 'Date',
        name: 'reportFiledDate',
      },
    ],
    hasFileUpload: false,
    title: 'OR Monthly Tax Filing Update',
  },
  road: {
    fields: [
      {
        config: {
          options: getFutureYears(2),
        },
        data_type: 'list->string',
        label: 'Filing Period',
        name: 'filingPeriod',
      },
      {
        data_type: 'string',
        label: 'Name filed under',
        name: 'name',
      },
      {
        data_type: 'string',
        label: 'Unit#',
        name: 'unitNumber',
      },
      {
        data_type: 'string',
        label: 'VIN#',
        name: 'vinNumber',
      },
    ],
    hasFileUpload: true,
    title: 'Road Taxes',
  },
  'scac-request': {
    fields: [
      {
        config: {
          maxlength: 4,
          msgForRegex: 'Value should be alphanumeric',
          regex: /^[a-z0-9]+$/i,
        },
        data_type: 'string',
        label: 'SCAC Code',
        name: 'scacCode',
      },
    ],
    title: 'SCAC Request',
  },
  'scac-update': {
    fields: [
      {
        config: {
          minDate: new Date(),
        },
        data_type: 'date',
        label: 'Permit Expiration Date',
        name: 'expirationDate',
      },
      {
        config: {
          maxlength: 4,
          msgForRegex: 'Value should be alphanumeric',
          regex: /^[a-z0-9]+$/i,
        },
        data_type: 'string',
        label: 'SCAC Code',
        name: 'scacCode',
      },
    ],
    hasFileUpload: true,
    title: 'SCAC Update',
  },
  trailer: {
    fields: [
      ...mutual_fields.slice(0, mutual_fields.length - 1),
      {
        config: {
          required: false,
        },
        data_type: 'bool',
        label: 'Permanent Check',
        name: 'isPermanantRegistration',
        showLabel: false,
      },
      {
        config: {
          minDate: new Date(),
          shouldHide: [
            (permanentCheck = false) => permanentCheck,
            'isPermanantRegistration',
          ],
        },
        data_type: 'date',
        label: 'Expiration Date',
        name: 'expirationDate',
      },
    ],
    hasFileUpload: true,
    title: 'Trailer Registration',
  },
  ucr: {
    fields: [
      {
        config: {
          minDate: new Date(),
        },
        data_type: 'date',
        label: 'Expiration Date',
        name: 'expirationDate',
      },
    ],
    title: 'UCR',
  },
};

const nameFields = [
  {
    data_type: 'string',
    label: 'First Name',
    name: 'firstName',
  },
  {
    data_type: 'string',
    label: 'Last Name',
    name: 'lastName',
  },
];

const dotFormConfig: Config = {
  clearinghouse: {
    fields: [
      ...nameFields,
      {
        config: {
          msgForRegex: 'Value should be alphanumeric',
          regex: /^[a-z0-9]+$/i,
        },
        data_type: 'string',
        label: 'CDL #',
        name: 'licenseNumber',
      },
      {
        config: {
          maxDate: getMaxDateForDOB(),
        },
        data_type: 'date',
        label: 'Date Of Birth',
        name: 'dob',
      },
      {
        config: {
          options: ['Prohibited', 'Non Prohibited'].map((el) => ({
            label: el,
            value: el,
          })),
        },
        data_type: 'list->string',
        label: 'Query Result',
        name: 'queryResult',
      },
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Date Ran',
        name: 'dateRan',
      },
    ],
    hasFileUpload: true,
    title: 'Clearinghouse Query',
  },
  'driver-application': {
    fields: [
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Signed Date',
        name: 'signedDate',
      },
      {
        config: {
          defaultMonth: [
            function (date: string) {
              const temp = new Date(date);
              const month = temp.getMonth();
              return month + 1;
            },
            'signedDate',
          ],
          minDate: [
            function (date: string) {
              const temp = new Date(date);
              temp.setDate(temp.getDate() + 30);
              return temp;
            },
            'signedDate',
          ],
        },
        data_type: 'date',
        label: 'Expiration Date',
        name: 'expirationDate',
      },
    ],
    hasFileUpload: true,
    title: 'Pre-employment MVR Authorization',
  },
  'drivers-license': {
    fields: [
      ...nameFields,
      {
        config: {
          msgForRegex: 'Value should be alphanumeric',
          regex: /^[a-z0-9]+$/i,
        },
        data_type: 'string',
        label: "Driver's License Number",
        name: 'licenseNumber',
      },
      {
        config: {
          aliasKey: 'licenseState',
        },
        data_type: 'list->string',
        label: "Driver's License State",
        name: 'licenseStateId',
      },
      {
        config: {
          options: dirverLicenseTypes.map((el) => ({ label: el, value: el })),
        },
        data_type: 'list->string',
        label: "Driver's License Class",
        name: 'licenseType',
      },
      {
        config: {
          // isOptional: true,
          aliasKey: 'driverLicenseEndorsements',
          disabled: [
            'licenseType',
            (licenseType: string) =>
              licenseTypesWithNoEndorsements.includes(licenseType),
          ],
          isOptional: true,
          min: 1,
          options: ['H', 'N', 'P', 'T', 'S', 'X'].map((el) => ({
            label: el,
            value: el,
          })),
          // shouldHide: [
          //   (licenseType: string) => licenseType === 'Non-CDL',
          //   'licenseType',
          // ],
          when: [
            'licenseType',
            (licenseType: string, schema: any) =>
              licenseTypesWithNoEndorsements.includes(licenseType)
                ? schema.min(0)
                : schema.min(1, 'Required'),
          ],
        },
        data_type: 'list',
        label: 'Endorsements',
        name: 'licenseEndorsements',
      },
      {
        config: {
          minDate: new Date(),
        },
        data_type: 'date',
        label: 'Expiration Date',
        name: 'licenseExpirationDate',
      },
      {
        config: {
          maxDate: getMaxDateForDOB(),
        },
        data_type: 'date',
        label: 'Date Of Birth',
        name: 'dob',
      },
    ],
    hasFileUpload: true,
    title: "Driver's license",
  },
  dtaf: {
    fields: [
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Signed Date',
        name: 'signedDate',
      },
      {
        config: {
          defaultMonth: [
            function (date: string) {
              const temp = new Date(date);
              const month = temp.getMonth();
              return month + 1;
            },
            'signedDate',
          ],
          minDate: [
            function (date: string) {
              const temp = new Date(date);
              temp.setDate(temp.getDate() + 30);
              return temp;
            },
            'signedDate',
          ],
        },
        data_type: 'date',
        label: 'Expiration Date',
        name: 'expirationDate',
      },
    ],
    hasFileUpload: true,
    title: 'Pre-employment MVR Authorization',
  },
  'medical-card': {
    fields: [
      ...nameFields,
      {
        config: {
          msgForRegex: 'Value should be alphanumeric',
          regex: /^[a-z0-9]+$/i,
        },
        data_type: 'string',
        label: 'CDL #',
        name: 'licenseNumber',
      },
      {
        config: {
          maxDate: getMaxDateForDOB(),
        },
        data_type: 'date',
        label: 'Date Of Birth',
        name: 'dob',
      },
      {
        config: {
          maxDate: new Date(),
          required: true,
        },
        data_type: 'date',
        label: 'Issue Date',
        name: 'issueDate',
        required: true,
      },
      {
        config: {
          minDate: new Date(),
        },
        data_type: 'date',
        label: 'Expiration Date',
        name: 'expirationDate',
        required: true,
      },
      {
        data_type: 'string',
        label: 'Examiner Name',
        name: 'medicalExaminerName',
      },
      {
        config: {
          maxlength: 10,
        },
        data_type: 'string',
        label: 'National Registry #',
        name: 'nationalRegistryNumber',
      },
    ],
    hasFileUpload: true,
    title: 'Medical Card',
  },
  mvr: {
    fields: [
      ...nameFields,
      {
        config: {
          msgForRegex: 'Value should be alphanumeric',
          regex: /^[a-z0-9]+$/i,
        },
        data_type: 'string',
        label: 'CDL #',
        name: 'licenseNumber',
      },
      {
        config: {
          maxDate: getMaxDateForDOB(),
        },
        data_type: 'date',
        label: 'Date Of Birth',
        name: 'dob',
      },
      {
        config: {
          max: 300,
          min: 0,
        },
        data_type: 'number',
        label: 'Total Points',
        name: 'totalPoints',
      },
      {
        config: {
          min: 0,
        },
        data_type: 'number',
        label: 'No. of accidents',
        name: 'noOfAccidents',
      },
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Date Ran',
        name: 'dateRan',
      },
    ],
    hasFileUpload: true,
    title: 'MVR Record',
  },
  'pre-emp-mvr': {
    fields: [
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Signed Date',
        name: 'signedDate',
      },
      {
        config: {
          defaultMonth: [
            function (date: string) {
              const temp = new Date(date);
              const month = temp.getMonth();
              return month + 1;
            },
            'signedDate',
          ],
          minDate: [
            function (date: string) {
              const temp = new Date(date);
              temp.setDate(temp.getDate() + 30);
              return temp;
            },
            'signedDate',
          ],
        },
        data_type: 'date',
        label: 'Expiration Date',
        name: 'expirationDate',
      },
    ],
    hasFileUpload: true,
    title: 'Pre-employment MVR Authorization',
  },
  'trailer-annual-inspection': {
    fields: [
      {
        data_type: 'string',
        label: 'VIN#',
        name: 'vinNumber',
      },
      {
        data_type: 'string',
        label: 'Make',
        name: 'make',
      },
      {
        config: {
          options: getYearsWithLimit(new Date(), 50, 10),
        },
        data_type: 'list->string',
        label: 'Year',
        name: 'year',
      },
      {
        data_type: 'string',
        label: 'Unit #',
        name: 'unitNumber',
      },
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Inspection Date',
        name: 'inspectionDate',
      },
    ],
    hasFileUpload: true,
    title: 'Annual Inspection',
  },
  'vehicle-annual-inspection': {
    fields: [
      {
        data_type: 'string',
        label: 'VIN#',
        name: 'vinNumber',
      },
      {
        data_type: 'string',
        label: 'Make',
        name: 'make',
      },
      {
        config: {
          options: getYearsWithLimit(new Date(), 50, 10),
        },
        data_type: 'list->string',
        label: 'Year',
        name: 'year',
      },
      {
        data_type: 'string',
        label: 'Unit #',
        name: 'unitNumber',
      },
      {
        config: {
          maxDate: new Date(),
        },
        data_type: 'date',
        label: 'Inspection Date',
        name: 'inspectionDate',
      },
    ],
    hasFileUpload: true,
    title: 'Annual Inspection',
  },
};
// const todoType = dot_form_slugs.includes(variant)  && 'dot' || todo_form_slugs.includes(variant) && 'tpts';

export const formConfig: Config = {
  ...tptsFormConfig,
  ...dotFormConfig,
};

type TodoTypeConfig = {
  [key: string]: {
    requestValue?: string;
    updateValue?: string;
    update?: boolean;
    request?: boolean;
    redirect?: string;
    resolve?: boolean;
    resolveValue?: string;
    access?: boolean;
  };
};

export const todoTypeBasedConfig: TodoTypeConfig = {
  'AR Report': {
    access: true,
    redirect: '',
    request: true,
    requestValue: 'ar-request',
    update: true,
    updateValue: 'ar-update',
  },
  'Annual Report': {
    access: rootStore.authStore.NavbarAccess?.tpts.annualReport,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.ANNUALREPORT,
    request: false,
    requestValue: 'annual',
    update: true,
    updateValue: 'annual',
  },
  // 'CA/MCP': {
  //   redirect: '',
  //   request: false,
  //   requestValue: 'ca-mcp',
  //   update: false,
  //   updateValue: 'ca-mcp',
  // },
  'Cab Card/ IRP Registration': {
    access: rootStore.authStore.NavbarAccess?.tpts.irpService,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.IRPSERVICE,
    request: false,
    requestValue: '',
    update: true,
    updateValue: 'irp',
  },
  'Clearinghouse Query': {
    access: true,
    update: true,
    updateValue: 'clearinghouse',
  },
  'DOT Biennal Update': {
    access: rootStore.authStore.NavbarAccess?.tpts.dotUpdate,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.DOTUPDATE,
    request: false,
    requestValue: '',
    update: true,
    updateValue: 'dot-biennial',
  },
  'DOT VMT Update': {
    access: rootStore.authStore.NavbarAccess?.tpts.dotUpdate,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.DOTUPDATE,
    request: false,
    requestValue: '',
    update: true,
    updateValue: 'dot-vmt',
  },
  'Driver Application': {
    access: true,
    resolve: true,
    resolveValue: 'driver-application',
  },
  "Driver's License": {
    access: true,
    update: true,
    updateValue: 'drivers-license',
  },
  'Fuel Tax Filing': {
    access: rootStore.authStore.NavbarAccess?.tpts.ftQuarterlyFiling,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.FTQUARTERFILLING,
    request: false,
    requestValue: 'fuel',
    update: true,
    updateValue: 'fuel',
  },
  'Hazmat Permit': {
    access: true,
    redirect: '',
    request: true,
    requestValue: 'hazmat-request',
    update: true,
    updateValue: 'hazmat-update',
  },
  'Hazmat Training': {
    access: true,
    update: true,
    updateValue: 'hazmat-training',
  },
  'IFTA Permit': {
    access: rootStore.authStore.NavbarAccess?.tpts.ifta,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.IFTA,
    request: false,
    requestValue: 'ifta',
    update: true,
    updateValue: 'ifta',
  },
  'IRP Permit': {
    access: rootStore.authStore.NavbarAccess?.tpts.irpService,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.IRPSERVICE,
    request: false,
    requestValue: '',
    update: true,
    updateValue: 'irp-permit',
  },
  'Local Registration': {
    access: true,
    update: true,
    updateValue: 'local',
  },
  MVR: {
    access: rootStore.authStore.NavbarAccess?.dot.mvr,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.MVR,
    request: false,
    requestValue: 'MVR',
    update: true,
    updateValue: 'mvr',
  },
  'MVR Authorization': {
    access: rootStore.authStore.NavbarAccess?.dot.mvr,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.MVR,
    request: false,
    resolve: true,
    resolveValue: 'mvr',
    update: false,
  },
  'Medical Card': {
    access: true,
    update: true,
    updateValue: 'medical-card',
  },
  'NM Permit': {
    access: rootStore.authStore.NavbarAccess?.tpts.statePermits,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.STATEPERMITS,
    request: false,
    requestValue: 'nm',
    update: true,
    updateValue: 'nm',
  },
  'NY Permit': {
    access: rootStore.authStore.NavbarAccess?.tpts.statePermits,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.STATEPERMITS,
    request: false,
    requestValue: 'ny',
    update: true,
    updateValue: 'ny',
  },
  'OR Monthly Tax Filing': {
    access: true,
    redirect: '',
    request: true,
    requestValue: 'or-monthly-request',
    update: true,
    updateValue: 'or-monthly-update',
  },
  'OR Permit': {
    access: rootStore.authStore.NavbarAccess?.tpts.statePermits,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.STATEPERMITS,
    request: false,
    requestValue: 'or',
    update: true,
    updateValue: 'or',
  },
  // 'Pre-employment MVR': {
  //   redirect: PrivateRoutes.SERVICEREQUEST.TODO.MVR,
  //   request: false,
  //   requestValue: '',
  //   update: false,
  //   updateValue: 'pre-emp-mvr',
  // },
  PSP: {
    access: true,
    resolve: true,
    resolveValue: 'psp',
  },
  'Pre-Employment Driver Application': {
    access: true,
    resolve: true,
    resolveValue: 'driver-application',
  },
  'Pre-Employment Driver License': {
    access: true,
    update: true,
    updateValue: 'drivers-license',
  },
  'Pre-Employment Medical Card': {
    access: true,
    update: true,
    updateValue: 'medical-card',
  },
  'Pre-Employment PSP Authorization': {
    access: true,
    resolve: true,
    resolveValue: 'psp',
  },
  'Road Taxes': {
    access: rootStore.authStore.NavbarAccess?.tpts.roadTaxes,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.ROADTAX,
    request: false,
    requestValue: 'road',
    update: true,
    updateValue: 'road',
  },
  SCAC: {
    access: true,
    redirect: '',
    request: true,
    requestValue: 'scac-request',
    update: true,
    updateValue: 'scac-update',
  },
  'Termination Acknowledgment Form': {
    access: true,
    update: true,
    updateValue: 'dtaf-request',
  },
  'Trailer Annual Inspection': {
    access: true,
    update: true,
    updateValue: 'trailer-annual-inspection',
  },
  'Trailer Registration': {
    access: true,
    update: true,
    updateValue: 'trailer',
  },
  UCR: {
    access: rootStore.authStore.NavbarAccess?.tpts.ucr,
    redirect: PrivateRoutes.SERVICEREQUEST.TODO.UCR,
    request: false,
    requestValue: '',
    update: true,
    updateValue: 'ucr',
  },
  'Unit Termination Acknowledgment Form': {
    access: true,
    update: true,
    updateValue: 'utaf-request',
  },
  'Vehicle Annual Inspection': {
    access: true,
    update: true,
    updateValue: 'vehicle-annual-inspection',
  },
};

const getOptionsWithSection = (section = '') => [
  {
    label: `Upload Signed ${section} Authorization`,
    value: 'upload authorization',
  },
  {
    label: `Upload a copy of driver's ${section} record`,
    value: 'upload record',
  },
  {
    label: 'Request Driver to sign authorization.',
    value: 'send to driver',
  },
];

// New driver setup attachments sections
const configArray = [
  {
    caseIdName: 'Drivers Application',
    fileType: 'Driver Application',
    label: 'Driver Application',
    options: [
      {
        label: 'I have the document and will upload it',
        value: 'upload record',
      },
      {
        label: 'Request driver to upload driver application',
        value: 'send to driver',
      },
    ],
    required: true,
    shouldShowForm: (selectedOption = '') => selectedOption === 'upload record',
    showError: false,
  },
  {
    caseIdName: 'Drivers License',
    defaultValue: 'send to driver',
    fileType: "Driver's License",
    label: "Driver's License",
    labelForNoDoc:
      'If you don’t upload this document, a To-Do will be sent to the driver to upload it',
    options: [],
  },
  {
    caseIdName: 'MVR',
    defaultValue: 'send to driver',
    fileType: 'Pre-employment MVR',
    label: 'MVR',
    // options: [...getOptionsWithSection('MVR')],
    labelForNoDoc:
      " If you don't upload the record, Simplex will pull the drivers MVR",
    // required: true,
    todoFormVariant: 'mvr',
  },
  {
    caseIdName: 'Pre-employment Drug Test',
    defaultValue: 'send to driver',
    fileType: 'Pre-employment Drug Test Result',
    label: 'Pre-employment Drug Test Result',
    labelForNoDoc:
      "If you don't upload the record, Simplex will send the lab order to the driver",
    options: [],
  },

  {
    caseIdName: 'Medical Card',
    defaultValue: 'send to driver',
    fileType: 'Medical Card & National Registry',
    label: 'Medical Card',
    labelForNoDoc:
      'If you don’t upload this document, a To-Do will be sent to Driver to upload it',
    options: [],
    todoFormVariant: 'medical-card',
  },
  {
    caseIdName: 'Clearinghouse Query',
    defaultValue: 'send to driver',
    fileType: 'Pre-employment Query Result',
    label: 'Clearinghouse Query',
    labelForNoDoc:
      "If you don't upload the record, Simplex will contact the driver to run the query.",
    options: [],
    todoFormVariant: 'clearinghouse',
  },
  {
    caseIdName: 'PSP',
    fileType: 'PSP Authorization Form',
    isOptional: true,
    label: 'PSP',
    name: 'psp',
    options: [...getOptionsWithSection('PSP')],
    shouldShowForm: (selectedOption = '') =>
      selectedOption === 'upload record' ||
      selectedOption === 'upload authorization',
  },
  {
    caseIdName: 'Background Check',
    fileType: 'Background Check Result',
    isOptional: true,
    label: 'Criminal Background Check',
    name: 'criminalBackground Check',
    options: [
      {
        label: 'Upload Background check record',
        value: 'upload record',
      },
      {
        label: 'Request Simplex to run Background Check',
        value: 'send to driver',
      },
    ],
    shouldShowForm: (selectedOption = '') => selectedOption === 'upload record',
  },
];
export const new_driver_docs = (arr = configArray) =>
  arr.map((el) => ({
    ...el,
    attachment: null,
    requestType: '-',
    value: null,
  }));

export const tpts_form_slugs = [
  'irp',
  'irp-permit',
  'local',
  'trailer',
  'ucr',
  'road',
  'annual',
  'ifta',
  'fuel',
  'or-monthly-update',
  'or-monthly-request',
  'dot-biennial',
  'dot-vmt',
  'ar-update',
  'ar-request',
  'hazmat-update',
  'hazmat-request',
  'scac-update',
  'scac-request',
  'ca-mcp',
];

export const tpts_with_equipment_select_slugs = ['nm', 'ny', 'or'];

export const dot_form_slugs = [
  'drivers-license',
  'medical-card',
  'pre-emp-mvr',
  'mvr',
  'clearinghouse',
  'driver-application',
  'vehicle-annual-inspection',
  'trailer-annual-inspection',
  'dtaf-request',
  'utaf-request',
];

// roles to which user can be demoted based on user type
export const roleUpdateListByUserType: Array<any> = [
  {
    role: 'Super Admin',
    type: 'driver',
    value: ['Company Admin', 'Driver'],
  },
  {
    role: 'Company Admin',
    type: 'driver',
    value: ['Driver'],
  },
  {
    role: 'Super Admin',
    type: 'owner operator',
    value: ['Company Admin', 'Owner Operator'],
  },
  {
    role: 'Company Admin',
    type: 'owner operator',
    value: ['Owner Operator'],
  },
];

// roles to which user can be promoted based on role type
export const roleUpdateListByRole: Array<any> = [
  {
    role: 'Driver',
    value: ['Super Admin', 'Company Admin'],
  },
  {
    role: 'Owner Operator',
    value: ['Super Admin', 'Company Admin'],
  },
  {
    role: 'Company Admin',
    value: ['Super Admin'],
  },
  {
    role: 'DER',
    value: ['Super Admin'],
  },
];

// roles which can be assigned based on user type
export const roleAssignListByUserType: Array<any> = [
  {
    type: 'driver',
    value: ['Super Admin', 'Company Admin', 'Driver'],
  },
  {
    type: 'owner',
    value: ['Super Admin'],
  },
  {
    type: 'owner operator',
    value: ['Super Admin', 'Company Admin', 'Owner Operator'],
  },
  {
    type: 'der',
    value: ['Super Admin', 'Super Admin', 'DER'],
  },
];

export const TODO_CATEGORY = {
  TRAINING: 'Training',
};

export const consentAcknowledgmentTermination = {
  DTAF: ' Driver Termination and Rehire Disclosure and consent to terminate the listed driver for the reason provided',
  UTAF: ' Equipment Termination Disclosure provided by Simplex Group and consent to terminate the selected equipment',
};
